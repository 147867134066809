import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const DashboardLogo = ({ active = false }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.6665 3.66675H9.1665V11.0001H3.6665V3.66675Z" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.6665 14.6667H9.1665V18.3334H3.6665V14.6667Z" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8332 11.0001H18.3332V18.3334H12.8332V11.0001Z" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8332 3.66675H18.3332V7.33341H12.8332V3.66675Z" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default DashboardLogo