import React from 'react'
import { appGreyColor, appPrimaryColor } from 'theme/colors'

const SidebarHeartLogo = ({ active = false }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8747 9.52437L9.99971 16.3334L3.12471 9.52437C2.67124 9.0831 2.31405 8.55272 2.07563 7.96662C1.83721 7.38053 1.72272 6.75141 1.73938 6.1189C1.75604 5.48638 1.90349 4.86416 2.17243 4.29143C2.44138 3.7187 2.826 3.20786 3.30207 2.79107C3.77814 2.37429 4.33535 2.06059 4.93862 1.86973C5.54188 1.67887 6.17813 1.61499 6.80729 1.68211C7.43646 1.74922 8.04491 1.94588 8.59434 2.2597C9.14377 2.57352 9.62227 2.99771 9.99971 3.50554C10.3788 3.00139 10.8578 2.58092 11.4069 2.27043C11.9559 1.95994 12.5632 1.76612 13.1906 1.7011C13.818 1.63608 14.452 1.70126 15.0531 1.89257C15.6541 2.08387 16.2092 2.39717 16.6836 2.81287C17.158 3.22857 17.5415 3.73772 17.8101 4.30844C18.0787 4.87917 18.2266 5.49918 18.2445 6.12969C18.2624 6.7602 18.15 7.38762 17.9143 7.97269C17.6786 8.55775 17.3247 9.08788 16.8747 9.52987" stroke={active ? appPrimaryColor : appGreyColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default SidebarHeartLogo