import { IconButton } from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { greyLabelColor } from 'theme/colors';
import { setSidebar } from 'slices/user.slice';

const SidebarController = () => {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.user.sidebar);

  const handleArrowButton = () => {
    dispatch(setSidebar(!sidebar));
  }

  return (
    <IconButton sx={{
      display: {
        xs: 'none',
        sm: 'flex'
      },
      position: 'absolute',
      top: '40px',
      // left: "-12px",
      right: '-12px',
      border: '1px solid #DDDFE4',
      borderRadius: '50%',
      padding: '4px',
      '&:hover': {
        backgroundColor: 'inherit',
      },
      zIndex: 1300
    }}
      onClick={handleArrowButton}
    >
      <ArrowBackIosNewOutlinedIcon sx={{ color: greyLabelColor, fontSize: '1rem', rotate: sidebar ? '0deg' : '180deg' }} />
    </IconButton>
  )
}

export default SidebarController