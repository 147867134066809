import { authApi } from 'apis/auth.api';
import { useDispatch, useSelector } from 'react-redux'

const useSelf = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.userInfo);

  const refetchUser = () => {
    dispatch(authApi.util.invalidateTags(["User"]));
  }

  return {
    ...user,
    refetchUser: refetchUser,
  }
}

export default useSelf