export const routes = {
  patient: [
    // "/dashboard",
    "/appointments",
    "/health-records",
    "/supplement-store",
    "/settings",
    "/new-appointment",
    "/health-articles",
    "/consultation",
    "/order-tracking",
    "/order",
    "/supplement",
    "chat",
  ],
  provider: [
    "/appointments",
    "/patient-management",
    "/settings",
    "/consultation",
  ],
  pharmacy: [
    // "/medicines",
    // "/order-management",
    "/settings"
  ],
}

export const withoutLayoutRoutes = [/^\/new-appointment$/,
  /^\/reschedule-appointment\/[^/]+$/,
  /^\/personal-info$/,
  /^\/checkout$/,
  /^\/schedule-appointment\/[^/]+$/,
  /^\/order-confirmed$/,
  /^\/cart$/,
  /^\/about$/,
  /^\/user-verification$/,
];