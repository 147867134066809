// import { useGetUserQuery } from 'apis/auth.api';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { localStorageService } from 'shared/services/localStorageService';
import { setUser, resetUser } from 'slices/user.slice';
import useGetCurrentUser from './useGetCurrentUser';
import moment from 'moment-timezone';
// import moment from 'moment-timezone/moment-timezone';
import useFcm from './useFcm';
// import { useQuery } from '@apollo/client';
// import { GET_CURRENT_USER, GET_DOCUMENTS, GET_WHITE_LABEL_SETTINGS } from 'graphql/queries';

const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state?.user?.userInfo)
  const { updateFcmToken } = useFcm();
  // const { data: userData } = useQuery(GET_CURRENT_USER)
  // const { data: documents } = useQuery(GET_DOCUMENTS)
  // const { data: currentUser } = useQuery(GET_WHITE_LABEL_SETTINGS)
  // console.log(userData, "data", documents, "documents", currentUser, "currentUser");
  const { data, isSuccess, isError, isLoading } = useGetCurrentUser();
  useEffect(() => {
    if (isSuccess) {
      updateFcmToken();
      dispatch(setUser(data));
      moment.tz.setDefault(data?.timezone)
    }

    const handleStorageChange = () => {
      if (localStorage.getItem("isLogout") === "true") {
        dispatch(resetUser());
      }
    }
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    }
  }, [isSuccess, data, dispatch])

  if (isLoading) {
    return { isLoading, authenticated: false };
  }

  if (isError) {
    dispatch(resetUser());
    localStorageService.logout();
    return { isLoading, authenticated: false };
  }
  return {
    authenticated: isSuccess || (Boolean(user?.id) && localStorageService.getToken()),
    isLoading: isLoading || (!user?.id && localStorageService.getToken()),
    healthieToken: data?.healthie_api_token,
    userId: data?.id
  };
};

export default useAuth;
