import { Badge, IconButton, Menu, Stack, Typography, Divider } from '@mui/material'
import { useEffect, useState } from 'react'

import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DropdownNotificationListing from './DropdownNotificationListing';
import Link from 'components/Link';
import useFcm from 'shared/hooks/useFcm';
import { appWhite, blueLabelColor } from 'theme/colors';
import { useDispatch, useSelector } from 'react-redux';
import { resetUnreadNotifications, setcount } from 'slices/user.slice';
import { useUnreadNotificationsCountQuery } from 'apis/auth.api';
import { localStorageService } from 'shared/services/localStorageService';

const WrapperStyle = {
  top: "12px",
  '& .MuiPaper-root': {
    // boxShadow: "0px 8px 8px -4px #1018280A",
    width: '460px',
    // height: '465px',
    overflowY: 'auto',
    radius: '16px',
    // boxShadow: 'none',
    '& .MuiList-root': {
      height: '465px',
      padding: '0px!important',
      display: 'flex',
      flexDirection: 'column',
      gap: "12px",
    }
  }
}
const iconButtonStyle = {
  border: `1px solid #DDDFE4`,
  color: '#9E9E9E',
  borderRadius: '50%',
}
const NotificationsDropdown = () => {
  const dispatch = useDispatch();
  const unreadNotifications = useSelector(state => state.user.unreadNotifications) ?? [];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { updateFcmToken } = useFcm();
  // const [count, setCount] = useState(true);
  const count = useSelector(state => state.user.count);
  // const firstTimeLogin = useSelector(state => state.user.firstTimeLogin);
  const firstTimeLogin = localStorageService.getFirstLoginNotifications();

  const { data } = useUnreadNotificationsCountQuery('', {
    skip: !count
  });
  const { count: unreadCount = 0 } = data || {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const resp = Notification.permission;
    Notification.requestPermission().then((permission) => {
      if (resp === 'granted' && permission === 'granted') {
        console.log('granted');
        // do nothing here
      } else if (permission === 'granted') {
        updateFcmToken();
      } else {
        console.log('Notifications are blocked. Please enable them in your browser settings.');
        alert('Please enable notifications in your browser settings.');
        return;
      }
    }).catch(() => { })
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(resetUnreadNotifications());
    dispatch(setcount(false));
    // dispatch(setFirstTimeLogin(false))
    localStorageService.setFirstLoginNotifications(false);
  };

  useEffect(() => {
    if (firstTimeLogin) {
      dispatch(resetUnreadNotifications());
      localStorageService.setFirstLoginNotifications(false);
    }
  }, [firstTimeLogin])

  return (
    <>
      <Badge
        badgeContent={unreadNotifications?.length + (count ? unreadCount : 0)}
        // badgeContent={unreadNotifications?.length + (count ? unreadCount : 0)} 
        sx={{
          '& .MuiBadge-badge ': {
            backgroundColor: '#E3757A',
            top: '8px',
            right: '4px',
            color: appWhite
          }
        }}>
        <IconButton sx={iconButtonStyle} onClick={handleClick}>
          <NotificationsOutlinedIcon />
        </IconButton>
      </Badge>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock
        disableAutoFocusItem
        sx={WrapperStyle}
      >
        <div>
          <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography fontSize={18} color="customColors.blueLabel" fontWeight={600}>Notifications</Typography>
              {/* <Badge badgeContent={6} color="primary" /> */}
            </Stack>
            <IconButton onClick={handleClose}> <CloseOutlinedIcon sx={{ color: blueLabelColor }} /> </IconButton>
          </Stack>
          <Divider />
        </div>
        <DropdownNotificationListing
          gap={0}
          unreadCount={count ? data?.count ?? 0 : 0}
          handleClose={handleClose}
        // unreadNotifications={unreadNotifications}
        />
        <div onClick={handleClose}>
          <Link
            textAlign="center"
            to="/message-center?tab=notifications"
            color={theme => theme.palette.text.appGrey}
            variant="body2" fontWeight={600}
            pb={2}
          >
            See all
          </Link>
        </div>
      </Menu>
    </>
  )
}

export default NotificationsDropdown