import { Chip } from '@mui/material'
import { unverifiedIcon, verifiedIcon } from 'assets/svgIcons';
import React from 'react'
import useSelf from 'shared/hooks/useSelf';

const VerificationChip = () => {
  const {
    is_stripe_identity_verified = false
  } = useSelf();
  return (
    <Chip
      className={`verification-chip ${is_stripe_identity_verified ? "verified" : "not-verified"}`}
      icon={is_stripe_identity_verified ? verifiedIcon : unverifiedIcon}
      label={is_stripe_identity_verified ? "Verified" : "Unverified"}
    />
  )
}

export default VerificationChip