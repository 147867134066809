import { Stack, ListItem, List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { appBlackcolor, appBorderColor, appGreyColor } from 'theme/colors';
import DashboardLogo from 'assets/logos/DashboardLogo';
import Link from 'components/Link';
import { useLocation } from 'react-router-dom';
import AppointmentLogo from 'assets/logos/AppointmentLogo';
import HealthRecordLogo from 'assets/logos/HealthRecordLogo';
import PharmacyLogo from 'assets/logos/PharmacyLogo';
import SettingsLogo from 'assets/logos/SettingsLogo';
import HealthArticlesLogo from 'assets/logos/HealthArticlesLogo';
// import { routes } from 'shared/routes';
// import useGetCurrentUser from 'shared/hooks/useGetCurrentUser';
import { useSelector } from 'react-redux';
// import { setSidebar } from 'slices/user.slice';
// import { roles } from 'utils/constants';
import OrderTrackingLogo from 'assets/logos/OrderTrackingLogo';
import { useTheme } from '@emotion/react';
import ChatLogo from 'assets/logos/ChatLogo';
import SidebarHeartLogo from 'assets/logos/SidebarHeartLogo';

const sidebarRoutes = [
  { id: 1, label: 'Dashboard', subheading: 'Explore treatments, products and learn', value: 'dashboard', icon: (props) => <DashboardLogo {...props} />, path: "/dashboard" },
  { id: 2, label: 'My Health', subheading: 'Personalized health plans, records and labs', value: 'healthRecords', icon: (props) => <SidebarHeartLogo {...props} />, path: "/health-records" },
  { id: 3, label: 'Appointments', subheading: 'View appointments, reschedule, cancel', value: 'appointments', icon: (props) => <AppointmentLogo {...props} />, path: "/appointments" },
  // { id: 3, label: 'Medicines', value: 'medicines', icon: (props) => <AppointmentLogo {...props} />, path: "/medicines" },
  { id: 4, label: 'About Me', subheading: 'Allergies, medications, my stats', value: 'about-me', icon: (props) => <HealthRecordLogo {...props} />, path: "/about-me" },
  { id: 5, label: 'Message Center', subheading: 'Get help from a provider or support, view doctor messages', value: 'chat', icon: (props) => <ChatLogo {...props} />, path: "/message-center" },
  // { id: 5, label: 'Patients', value: 'patients', icon: (props) => <HealthRecordLogo {...props} />, path: "/patient-management" },
  // { id: 6, label: 'Order Management', value: 'order-management', icon: (props) => <AppointmentLogo {...props} />, path: "/order-management" },
  { id: 6, label: 'Supplement Store', subheading: 'Get your supplements from here', value: 'pharmacy', icon: (props) => <PharmacyLogo {...props} />, path: "/supplement-store" },
  { id: 7, label: 'My Orders', subheading: 'View purchase history', value: 'order-tracking', icon: (props) => <OrderTrackingLogo {...props} />, path: "/order-tracking" },
  { id: 8, label: 'Account Settings', subheading: 'Edit login information, address, preferences, email, all acknowledge disclosures', value: 'settings', icon: (props) => <SettingsLogo {...props} />, path: "/settings" },
]


const activeTab = {
  backgroundColor: "#F9EFF4",
  color: appBlackcolor,
}

const SidebarComponent = () => {
  // const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const { data } = useGetCurrentUser();
  // const { role } = data || {};
  // const userRoutes = sidebarRoutesComponent.filter(({ path }) => routes[role].includes(path));
  const sidebar = useSelector((state) => state.user.sidebar);


  const tabRootStyle = {
    borderRadius: '8px',
    minWidth: (sidebar || isSmallScreen) ? '220px' : '48px',
    color: appGreyColor,
  }

  const tabStyle = {
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '8px',
    '& .MuiListItemIcon-root ': {
      minWidth: (sidebar || isSmallScreen) ? '32px' : '24px',
    },
    ":hover": { backgroundColor: "inherit" }
  };

  // const handleArrowButton = () => {
  //   dispatch(setSidebar(!sidebar));
  // }

  return (
    <Stack
      height="calc(100vh - 105px)"
      width={(sidebar || isSmallScreen) ? "238px" : '76px'}
      borderRight={`1px solid ${appBorderColor}`}
      p="22px 14px"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      left={0}
      bottom={0}
      top={0}
      sx={{
        transition: 'width 0.3s ease-in-out',
        height: {
          xs: '100vh',
          sm: "calc(100vh - 105px)"
        },
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
      }}
    // overflow='hidden'
    >
      <List sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: (!sidebar && !isSmallScreen) ? '20px' : '0px',
      }}>
        {sidebarRoutes.map((item) => (
          <Link id={item.id} to={item.path} key={item.id} component='span'>
            <ListItem disablePadding sx={pathname === item.path ? { ...tabRootStyle, ...activeTab } : tabRootStyle}
            >
              <ListItemButton disableRipple sx={tabStyle}>
                <ListItemIcon>
                  {item.icon({ active: pathname === item.path ? true : false })}
                </ListItemIcon>
                {(sidebar || isSmallScreen) ? <ListItemText
                  sx={{
                    marginTop: '0px'
                  }}
                  primaryTypographyProps={{
                    // variant: 'body2',
                    fontSize: '15px',
                    fontWeight: 500,
                  }}
                  primary={item.label}
                  secondaryTypographyProps={{
                    fontSize: '11px',
                    color: pathname === item.path ? "customColors.greyLabel" : '#666F82'
                  }}
                  secondary={item.subheading}
                /> : null}
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      {/* {roles.PATIENT === role ? */}
      <Link to="/health-articles" component='span' width='100%'>
        <ListItem sx={pathname === "/health-articles" ? { ...tabRootStyle, ...activeTab, padding: "0px" } : { ...tabRootStyle, padding: "0px" }}>
          <ListItemButton disableRipple sx={tabStyle}>
            <ListItemIcon>
              <HealthArticlesLogo
                active={pathname === '/health-articles'}
              />
            </ListItemIcon>
            {(sidebar || isSmallScreen) ? <ListItemText
              sx={{
                marginTop: '0px'
              }}
              primaryTypographyProps={{
                variant: 'body2',
                fontWeight: 500
              }} primary="Health Articles"
              secondaryTypographyProps={{
                fontSize: '10px',
                color: pathname === '/health-articles' ? "customColors.greyLabel" : '#666F82'
              }}
              secondary="Services health articles"

            /> : null}
          </ListItemButton>
        </ListItem>
      </Link>
    </Stack>
  )
}

export default SidebarComponent