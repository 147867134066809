import React, { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import theme from "theme";
import ProtectedRoute from "components/Layout/ProtectedRoute";
import UnProtectedRoutes from "components/Layout/UnProtectedRoutes";
import Loader from 'components/Loader';
import { ThemeProvider } from '@mui/material/styles';

const _404 = lazy(() => import('containers/_404'));
const ForgetPassword = lazy(() => import('containers/Auth/ForgetPassword'));
const ResetPassword = lazy(() => import('containers/Auth/ResetPassword'));
const Login = lazy(() => import('containers/Auth/Login'));
const Register = lazy(() => import('containers/Auth/Register'));
const Landing = lazy(() => import('containers/Landing'));
const AccountSettings = lazy(() => import('containers/AccountSettings'));
const PatientHealthRecords = lazy(() => import('containers/PatientHealthRecords'));
const CreateAppointment = lazy(() => import('containers/CreateAppointment'));
const HealthArticles = lazy(() => import('containers/HealthArticles'));
const OrderTracking = lazy(() => import('containers/OrderTracking'));
const OrderDetail = lazy(() => import('containers/OrderDetail'));
const SupplementStore = lazy(() => import('containers/SupplementStore'));
const SupplementDetail = lazy(() => import('containers/SupplementDetail'));
const Appointments = lazy(() => import('containers/Appointments'));
const RescheduleAppointment = lazy(() => import('containers/RescheduleAppointment'));
const SupplementCheckout = lazy(() => import('components/SupplementCheckout'));
const StripeRedirect = lazy(() => import('containers/StripeRedirect'));
const MessageCenter = lazy(() => import('containers/MessageCenter'));
const AboutMe = lazy(() => import('containers/AboutMe'));
const ScheduleAppointment = lazy(() => import('containers/ScheduleAppointment'));
const Cart = lazy(() => import('containers/Cart'));
const Dashboard = lazy(() => import('containers/Dashboard'));
const StripeVerification = lazy(() => import('containers/StripeVerification'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='*' element={<Suspense fallback={<Loader pageLoader />}><_404 /></Suspense>} />
        <Route path='/' element={<Suspense fallback={<Loader pageLoader />}><Landing /></Suspense>} />
        <Route element={<Suspense fallback={<Loader pageLoader />}><UnProtectedRoutes /></Suspense>}>
          <Route path='/forget-password' element={<Suspense fallback={<Loader pageLoader />}><ForgetPassword /></Suspense>} />
          <Route path='/reset-password' element={<Suspense fallback={<Loader pageLoader />}><ResetPassword /></Suspense>} />
          <Route index path='/login' element={<Suspense fallback={<Loader pageLoader />}><Login /></Suspense>} />
          <Route path='/register' element={<Suspense fallback={<Loader pageLoader />}><Register /></Suspense>} />
        </Route>
        <Route element={<Suspense fallback={<Loader />}><ProtectedRoute /></Suspense>}>
          <Route path='/dashboard' element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>} />
          <Route path='/appointments' element={<Suspense fallback={<Loader pageLoader />}><Appointments /></Suspense>} />
          <Route path='/supplement-store' element={<Suspense fallback={<Loader pageLoader />}><SupplementStore /></Suspense>} />
          <Route path='/settings' element={<Suspense fallback={<Loader pageLoader />}><AccountSettings /></Suspense>} />
          <Route path='/health-records' element={<Suspense fallback={<Loader pageLoader />}><PatientHealthRecords /></Suspense>} />
          <Route path='/new-appointment' element={<Suspense fallback={<Loader pageLoader />}><CreateAppointment /></Suspense>} />
          <Route path='/health-articles' element={<Suspense fallback={<Loader pageLoader />}><HealthArticles /></Suspense>} />
          <Route path='/order-tracking' element={<Suspense fallback={<Loader pageLoader />}><OrderTracking /></Suspense>} />
          <Route path='/order' element={<Suspense fallback={<Loader pageLoader />}><OrderDetail /></Suspense>} />
          <Route path='/supplement/:id' element={<Suspense fallback={<Loader pageLoader />}><SupplementDetail /></Suspense>} />
          <Route path='/reschedule-appointment/:id' element={<Suspense fallback={<Loader pageLoader />}> <RescheduleAppointment /> </Suspense>} />
          <Route path='/message-center' element={<Suspense fallback={<Loader pageLoader />}> <MessageCenter /> </Suspense>} />
          <Route path='/checkout' element={<Suspense fallback={<Loader pageLoader />}> <SupplementCheckout /> </Suspense>} />
          <Route path='/order-confirmed' element={<Suspense fallback={<Loader pageLoader />}> <StripeRedirect /> </Suspense>} />
          <Route path='/about-me' element={<Suspense fallback={<Loader pageLoader />}> <AboutMe /> </Suspense>} />
          <Route path='/schedule-appointment/:id' element={<Suspense fallback={<Loader pageLoader />}> <ScheduleAppointment /> </Suspense>} />
          <Route path='/cart' element={<Suspense fallback={<Loader pageLoader />}> <Cart /> </Suspense>} />
          <Route path='/user-verification' element={<Suspense fallback={<Loader pageLoader />}> <StripeVerification />  </Suspense>} />
        </Route>
      </Routes>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000,
          success: {
            duration: 3000,
          },
          error: {}
        }}
      />
    </ThemeProvider>
  );
}
export default App;
