import {
  fetchBaseQuery,
  retry
} from "@reduxjs/toolkit/query/react";
// import toast from 'react-hot-toast';
import axios from 'axios';
import { localStorageService } from "shared/services/localStorageService";
import { customAlert } from "utils/helperFunctions";
// import { Alert } from "@mui/material";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    if (localStorageService?.getToken()) {
      headers.set("Authorization", `Bearer ${localStorageService.getToken()}`);
      // headers.set("Content-Type", "application/json");
    }
    return headers;
  },
});

export const refreshToken = async () => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/refresh/`, {
    refresh: localStorageService.getRefreshToken(),
  });
  return response.data;
};

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (!navigator.onLine) {
    customAlert({ severity: 'error', message: "No Internet Connection" })
    retry.fail("No Internet Connection")
  } else {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error && result?.error?.status === 401) {
      localStorageService.logout();
      window.location.reload();
      return retry.fail(result.error)
    }
    if (result?.error) {
      if (result?.error?.data?.error?.includes("Your card has expired")) {
        customAlert({ severity: 'error', message: "Your card has expired" })
      } else {
        customAlert({ severity: 'error', message: result?.error?.data?.error || result?.error?.data?.message })
      }
    }
    return result;
  }
};


