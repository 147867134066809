import { Typography } from "@mui/material";
import { Link as MuiLink } from 'react-router-dom';
import { styled } from '@mui/material/styles'

export const StyledLink = styled(MuiLink)(({ theme, width }) => ({
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: width && width,
}));

const Link = ({ to, children, width, anchorStyle, ...props }) => {
    return (
        <StyledLink to={to} width={width} style={anchorStyle}>
            <Typography {...props}>{children}</Typography>
        </StyledLink>
    )
}

export default Link