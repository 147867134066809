export const appPrimaryColor = "#B53673";
export const appPrimaryColorLight = "#F9EFF4";
export const appWhite = "#ffffff";
export const appBlackcolor = "#000000";
export const appGreyColor = "#616161";
export const labelColor = "#3B3B3B";
export const characterValidatorChecked = "#5CB85C";
export const appBorderColor = '#E4E7EC';
export const errorColor = "#D92D20";
export const greyLabelColor = "#49546B";
export const blueLabelColor = "#1E2B49";
export const backgroundColor = '#F6F8F9';
export const verifiedColor = '#187BEF';
