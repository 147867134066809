import {
  Avatar,
  // Badge,
  Stack, Typography
} from '@mui/material'
import { appointmentIcon, messageIcon, presscriptionIcon, treatmentIcon } from 'assets/svgIcons'
// import { appointment } from 'assets/svgIcons'
import { appWhite } from 'theme/colors'
import { notificationTimeDifference } from 'utils/helperFunctions'

const notificationIcons = {
  appointment: appointmentIcon,
  prescription: presscriptionIcon,
  treatment: treatmentIcon,
  message: messageIcon,
}

const NotificationCard = ({ created_at, message, title, clickAction,
  link
}) => {
  return (
    <div
      onClick={clickAction?.(link)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        p="16px 12px"
        borderRadius="8px"
        sx={{ backgroundColor: appWhite, cursor: 'pointer' }}
        // position="relative"
        gap={1}
        alignItems="flex-start"
      >
        <Stack direction="row" gap={2} minWidth={0}>
          <Avatar
            sx={{ height: 36, width: 36, minWidth: 0 }}
          >
            {notificationIcons.message}
            {/* {notificationIcons[notification_type]} */}
          </Avatar>
          <div style={{ minWidth: 0 }}>
            <Typography fontWeight={600} gutterBottom>{title}</Typography>
            <Typography color="customColors.blueLabel" variant="body2" >{message}</Typography>
          </div>
        </Stack>
        {/* <Stack direction="row" alignItems="center" gap={1.5}> */}
        <Typography
          color="#757575"
          variant="caption"
          noWrap
          minWidth="72px"
        // lineHeight="24px"
        >{notificationTimeDifference(created_at)}</Typography>
        {/* {!read ? <Badge variant="dot" color='success' /> : null} */}
        {/* <Badge variant="dot" color='success' /> */}
        {/* </Stack> */}
      </Stack>
    </div>
  )
}

export default NotificationCard