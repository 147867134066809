import { useGetUserQuery } from 'apis/auth.api';
// import { useSelector } from 'react-redux';
import { localStorageService } from 'shared/services/localStorageService';
import useNetwork from './useNetwork';

const useGetCurrentUser = () => {
  const isOnline = useNetwork();
  // const role = useSelector(state => state?.user?.userInfo.role);
  const { data, isSuccess, isError, isLoading } = useGetUserQuery(
    localStorageService.getToken(),
    { skip: localStorage.getItem("isLogout") === "true" || !isOnline }
  );

  return {
    data: data,
    isSuccess,
    isError,
    isLoading
  }

}

export default useGetCurrentUser