import { useState, useRef, useEffect } from 'react';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import * as ActionCable from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { customAlert } from 'utils/helperFunctions';
import { onError } from '@apollo/client/link/error';
// import useNetwork from 'shared/hooks/useNetwork';

// import { localStorageService } from 'shared/services/localStorageService';

let apolloClientInstance = null; // Singleton instance

const createApolloClient = ({ healthieToken }) => {
  // const healthieToken = localStorageService.getHealthieToken();

  if (!healthieToken) {
    console.warn('HealthieToken not found, Apollo Client not initialized.');
    return null;
  }

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HEALTHIE_GRAPHQL_URI,
  });

  // Middleware to add authorization header
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${healthieToken}`,
        Authorizationsource: 'Web',
      },
    });
    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        customAlert({ message: `GraphQL Error: ${message}`, severity: 'error' }); // Show alert or custom error handling
      });
    }

    if (networkError) {
      customAlert({ message: 'Network Error: Please check your internet connection.', severity: 'error' }); // Handle network error (show alert or log it)
    }
  });

  // WebSocket Link using ActionCable
  const cable = ActionCable.createConsumer(
    `wss://${process.env.REACT_APP_HEALTHIE_WEB_SOCKETS_URL}/subscriptions?token=${healthieToken}`
  );

  const wsLink = new ActionCableLink({ cable });

  // Split between WebSocket and HTTP link based on operation type
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  return {
    client: new ApolloClient({
      link: concat(authMiddleware, concat(errorLink, link)),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    }),
    cable, // Store ActionCable consumer for disconnection
  };
};

// Custom Hook for Apollo Client
const useApolloClient = ({ healthieToken }) => {
  // const isOnline = useNetwork();
  const [client, setClient] = useState(null);
  const cableRef = useRef(null);
  useEffect(() => {
    if (healthieToken) {
      if (!apolloClientInstance) {
        const { client: newClient, cable } = createApolloClient({ healthieToken });
        apolloClientInstance = newClient;
        cableRef.current = cable;
        setClient(newClient);
      } else {
        setClient(apolloClientInstance);
      }
    }

    // Cleanup on unmount
    return () => {
      if (cableRef.current) {
        cableRef.current.disconnect(); // Disconnect ActionCable
        apolloClientInstance = null;
        setClient(null);
        cableRef.current = null;
        console.log('ActionCable consumer disconnected.');
      }

      if (apolloClientInstance) {
        apolloClientInstance.clearStore().then(() => {
          console.log('Apollo Client cache cleared.');
          apolloClientInstance = null;
          setClient(null);
          cableRef.current = null;
        });
      }
    };
  }, [healthieToken])
  return {
    client
  };
};


export default useApolloClient;